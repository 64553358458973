Sofoot.modules.sondages = (() => {
    return {

        async addVote(data) {
            console.log("sondage", { data });
            const userId = Sofoot.modules.compte.getCurentUserId();

            await fetch(URL_API + `sondages/${data.sondageId}/reponse`, {
                method: 'POST',
                headers: {
                    'content-type': "application/json",
                },
                body: JSON.stringify({
                    voter_id: userId,
                    reponse: data.choixId,
                })
            }).then(response => response.json())
                .then(resultats => {
                    this.showResults(data.sondageId, resultats);
                    try {
                        fathom.trackEvent('Vote sur le sondage ' + data.sondageId + ' "' + data.question + '"')
                    } catch (e) {
                        console.log(e)
                    }
                });
        },
        showResults(sondageId, choix) {
            const payload = { sondageId, resultats: { choix } }
            emettreEvenement('voir-resultats-sondage', payload);
        },
        userHasVoted(sondageId) {
            if (sondageId) {
                localStorage.setItem("sondage-" + sondageId, "true");
            }
        },


        userCanVote(sondageId) {
            console.log(localStorage.getItem("sondage-" + sondageId));
            return localStorage.getItem("sondage-" + sondageId) ? false : true;
        },


        async getResultats(sondageId) {
            const now = new Date();
            const formattedDate = `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}`;
            const url = `${URL_API}sondages/${sondageId}/resultats?${formattedDate}`;

            return await fetch(url, { cache: "no-store" })
                .then(response => response.json())
                .then(resultats => resultats);
        },

        async set() {
            const sondage = document.querySelector('.sondage');
            const sondageId = sondage.dataset.id;
            if (!this.userCanVote(sondageId)) {
                const resultats = await this.getResultats(sondageId)
                this.showResults(sondageId, resultats);
            }

        },

        async start() {
            const sondage = document.querySelector('.sondage');
            if (!sondage) return;
            this.set();

            document.addEventListener('vote-sondage', async e => {
                const data = e.data;
                if (!this.userCanVote(data.sondageId)) {
                    return;
                }

                await this.addVote(data);
                if (data.multi) return;
                this.userHasVoted(data.sondageId);
            });


            // const payload = { sondageId: data.sondageId, resultats: sondages[data.sondageId] }
            // emettreEvenement('voir-resultats-sondage', payload);
            // this.activerRefreshAuto()
        }
    }

})();